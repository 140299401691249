.expired-ads-container {
  padding: 20px;
}

.expired-ads-list {
  list-style-type: none;
  padding: 0;
}

.expired-ad-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 5px;
}

.delete-button {
  background-color: #e91e63;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #ff4081;
}

/* Base styles for body */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Hide the scrollbar */
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
}

/* Set background and size for html and body */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #121212;
  /* Use min-height instead of height */
  min-height: 100%;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Additional styling for consistency across elements */
* {
  box-sizing: border-box;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #1e1e1e; /* Matching the dark background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Matching shadow */
  color: white; /* White text */
}

h1.ad-heading {
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  color: #e91e63; /* Accent color */
  margin-bottom: 15px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: white;
}

.ad-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #ccc; /* Softer white for labels */
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #333; /* Darker borders */
  border-radius: 4px;
  background-color: #121212; /* Dark input background */
  color: white; /* White text */
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #e91e63; /* Accent color on focus */
  outline: none;
}

textarea.form-control {
  resize: vertical;
}

.btn-submit {
  background-color: #e91e63; /* Accent color */
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #ff4081; /* Lighter accent on hover */
}

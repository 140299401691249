:root {
  --bg-color: #2c2c2c;
  --text-color: #fff;
  --button-color: #e91e63;
  --button-hover-color: #ff4081;
  --button-delete-color: #e91e63;
  --button-delete-hover-color: #ff4081;
  --highlight-color: #e91e63;
}

.admin-dashboard {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: var(--bg-color);
  color: var(--text-color);
  min-height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

.admin-title {
  text-align: center;
  color: var(--text-color);
  font-size: 2rem;
  margin-bottom: 15px;
}

.admin-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 8px;
  flex-wrap: wrap; /* To stack buttons on smaller screens */
}

.nav-button {
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: var(--button-hover-color);
}

.section {
  margin-bottom: 30px;
  background-color: #444;
  padding: 15px;
  border-radius: 8px;
}

.list {
  list-style: none;
  padding: 0;
}

.artist-item,
.invite-item {
  background-color: #2c2c2c;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.artist-item h3 {
  color: var(--highlight-color);
  margin-top: 8px;
}

ul,
li {
  list-style: none;
}
.social-link {
  text-decoration: none;
  color: var(--button-color);
  font-size: 0.9rem;
}
.work-images {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.work-image-item {
  margin-right: 5px;
}

.work-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  border: 3px solid var(--button-color);
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  gap: 8px;
  padding: 15px;
  border-radius: 20px;
}

.delete-user-button,
.delete-button {
  display: block;
  margin-top: 10px;
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  padding: 8px 14px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: var(--button-hover-color);
}

.search-bar {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  border: 2px solid var(--button-color);
  border-radius: 8px;
  background-color: #555;
  color: var(--text-color);
  font-size: 0.9rem;
}

.create-ad-button {
  display: block;
  margin: 15px auto;
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.create-ad-button:hover {
  background-color: var(--button-hover-color);
}

.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

@media screen and (max-width: 600px) {
  .admin-title {
    font-size: 1.8rem;
  }

  .admin-nav {
    flex-direction: column;
    gap: 10px;
  }

  .nav-button {
    font-size: 0.8rem;
    padding: 8px 12px;
  }

  .artist-item,
  .invite-item {
    padding: 10px;
  }

  .work-image {
    width: 70px;
    height: 70px;
  }

  .button-container {
    flex-direction: column;
    gap: 8px;
  }
}
